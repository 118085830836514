<template lang="pug">
  div
    v-data-table(
      dense
      :headers="headers"
      :items="unitIndexList"
      :loading="unitIndexGetting"
      :search="search"
    )
      template(v-slot:top)
        v-row
          v-col(cols="6")
            v-text-field(
              label="search"
              append-icon="mdi-magnify"
              v-model="search"
            )
</template>
<script>
import unitRepository from '@/repositories/unit.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [unitIndexVars, unitIndexVarNames] = requestVars({ identifier: 'unit-index' })
const unitIndexHandler = new VueRequestHandler(null, unitIndexVarNames)

const tableVars = () => ({
  headers: [
    { text: 'Unit', value: 'id' },
    { text: 'Description', value: 'description' },
  ],
  search: null,
})

export default {
  name: 'TableUnit',
  data () {
    return {
      ...unitIndexVars,
      ...tableVars(),
    }
  },
  created () {
    this.getUnits()
    this.initWebsocket()
  },
  methods: {
    initWebsocket () {
      const { echo } = this.$store.state.websocket
      const dbStoreEvent = response => {
        const { data, model } = response
        if (!model) return
        if (model === 'Unit') {
          this.unitIndexList.push(data)
        }
      }
      echo.private('database.event')
        .listen('DBStoreEvent', dbStoreEvent)
    },
    getUnits () {
      const handler = unitIndexHandler
      const repository = unitRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
  },
}
</script>